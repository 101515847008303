import React from "react";
import styled from "styled-components";

const Instructions = () => (
  <SInstructions>
    <h3>Что делать, если система плохо засчитывает выполнения? </h3>

    <h4>Неправильное определение ключевых точек </h4>
    <p>
      Чаще всего проблемы с распознаванием связаны с тем, что неправильно определяются основные точки на теле человека.
      Поэтому понаблюдайте за тем совпадают ли отрисованные точки с реальным положением частей тела и если это не так,
      то:
    </p>
    <p>✅ Убедитесь, что в комнате достаточно светло - модель плохо работает в темноте</p>
    <p>✅ Если скелетик перескакивает на окружающие вас вещи, то отодвиньте их</p>
    <p>✅ Проверьте, что вы полностью помещаетесь в кадр</p>

    <br />
    <h4>{"Маленький FPS (fps < 10)"}</h4>
    <p>
      Низкий fps может быть причиной плохого распознавания. Значение своего fps отображается на экране во время
      тренировки. Если ваш fps меньше 10:
    </p>
    <p>✅ Убедитесь, что у вас стабильное интернет соединение</p>
    <p>
      ✅ Попробуйте выполнить тренировку с другого устройства. <br />
      Чаще всего низкий fps означает нехватку мощностей вашего устройства для распознавания
    </p>
    <p>
      ✅ Если увеличить fps не получилось, то при быстрых упражнениях придется делать задержки в верхней и нижней позе
      упражнения, чтобы система успевала зафиксировать выполнение
    </p>
    <br />

    <h4>{"Неправильное выполнение/положение относительно камеры"}</h4>
    <p>
      ✅ Убедитесь, что вы выполняете тем же боком, что и анимация - это либо лицом, либо левым боком.
      <br /> Упражнения неправильным боком могут хуже засчитываться
    </p>
    <p>
      ✅ Попробуйте сделать "идеальное выполнение" - выполнения с максимальной амплитудой и правильной техникой. Система
      не засчитывает некачественные выполнения
    </p>
  </SInstructions>
);

export const SInstructions = styled.div`
  max-width: 920px;
  border-radius: 30px;
  background: #fff;
  padding: 32px;
  box-sizing: border-box;

  animation: anvil 0.3s cubic-bezier(0.38, 0.1, 0.36, 0.9) forwards;

  @keyframes anvil {
    0% {
      transform: scale(1) translateY(0px);
      opacity: 0;
      box-shadow: 0 0 0 rgba(241, 241, 241, 0);
    }
    1% {
      transform: scale(0.96) translateY(10px);
      opacity: 0;
      box-shadow: 0 0 0 rgba(241, 241, 241, 0);
    }
    100% {
      transform: scale(1) translateY(0px);
      opacity: 1;
      box-shadow: 0 0 500px rgba(241, 241, 241, 0);
    }
  }

  * {
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif,
      "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  }

  h4 {
    margin: 0;
    margin-bottom: 8px;
    font-size: 1.2em;
  }

  h3 {
    margin: 0;
    margin-bottom: 16px;
    font-size: 2em;
  }

  p {
    font-weight: normal;
    margin: 6px 0;
  }
`;

export default Instructions;
